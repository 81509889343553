import head from 'lodash/head'
import values from 'lodash/values'
import get from 'lodash/get'

const getLegType = (index, legs) => {
  if (legs.length === 2 && index > 0) return 'return'
  return 'departure'
}

const parseResults = (unparsed = []) => {
  return unparsed.map((result) => {
    return {
      ...result,
      id: result.uniqueId,
      component: 'FlightsListItem',
      legs: result.legs.map((leg, index, legs) => {
        return {
          ...leg,
          type: getLegType(index, legs)
        }
      })
    }
  })
}

const parseCarsResults = (results) => {
  return results.map((result) => {
    return {
      ...result,
      id: result.uniqueId,
      component: 'CarsListItem'
    }
  })
}

const filterFlights = (results = [], filters = {}) => {
  return results.filter((flight) => {
    let stopsMatch = false
    const selectedStops = filters.stops || [0, 1, 2]

    if (selectedStops.includes(0) && flight.stops === 0) stopsMatch = true
    if (selectedStops.includes(1) && flight.stops === 1) stopsMatch = true
    if (selectedStops.includes(2) && flight.stops >= 2) stopsMatch = true

    const outboundDurationMatch = filters.outboundDuration ? flight.legs[0].duration <= filters.outboundDuration : true
    const inboundDurationMatch = filters.inboundDuration && flight.legs[1]?.duration ? flight.legs[1].duration <= filters.inboundDuration : true

    const airlineMatch = filters.rejectedAirlines ? !filters.rejectedAirlines.includes(flight.mainAirlineCode) : true

    return stopsMatch && outboundDurationMatch && inboundDurationMatch && airlineMatch
  })
}

const filterCars = (results = [], filters = {}) => {
  return results.filter((result) => {
    const maxPrice = filters.totalPrice ? filters.totalPrice >= result.minPrice : true
    const options = filters.rejectedOptions ? !filters.rejectedOptions.some(filter => result.filters.includes(filter)) : true

    return maxPrice && options
  })
}

export const state = () => ({
  adsRequestParams: null,
  device: {},
  country: null,
  isGeolocated: false,
  isIdentified: false,
  ip: null,
  language: 'en',
  rtl: false,
  site: null,
  siteType: null,
  siteTitle: null,
  siteName: null,
  siteConfig: null,
  siteBrand: null,
  deviceType: null,
  entryId: null,
  isNewsletterModalOpen: false,
  isCompareToAdsModalLoaded: false,
  inlineAdDeeplink: null,
  searchResults: [],
  searchFilters: {
    stops: null,
    outboundDuration: null,
    inboundDuration: null
  },
  searchAirlines: [],
  passangers: {
    adults: null,
    children: null
  },
  searchCarsFilters: {
    rejectedOptions: null,
    totalPrice: null
  },
  searchCarsCheckboxes: null,
  clearAllOptionsEnabled: false,
  searchCarLocationCodes: null,
  deeplinks: [],
  inlineAds: [],
  analyticEvents: [],
  translationRedirects: {},
  relatedSearch: {},
  ostype: null,
  isDebugEnabled: 0,
  browser: {
    name: null,
    version: null,
    os: null,
    type: null
  },
  compareItems: [],
  sortingType: null,
  sortResults: {
    recommended: null,
    fastest: null,
    cheapest: null
  },
  hasLocalCurrency: false,
  localCurrency: false
})

export const getters = {
  analyticEvents (state) {
    return state.analyticEvents
  },
  phone (state) {
    const { country, phones } = state
    return get(phones.list[country ? country.toLowerCase() : 'us'] || phones.list.ww || head(values(phones.list)), 'number')
  },
  deviceCategory (state) {
    const device = state.device
    if (device.isDesktop) return 'desktop'
    if (device.isTablet) return 'tablet'
    return 'mobile'
  },
  parsedFilteredResults (state) {
    const { searchResults, searchFilters } = state
    return parseResults(filterFlights(searchResults, searchFilters))
  },
  parsedFilteredCarsResults (state) {
    const { searchResults, searchCarsFilters } = state
    return parseCarsResults(filterCars(searchResults, searchCarsFilters))
  }
}

export const mutations = {
  setAdsRequestParams (state, values) {
    state.adsRequestParams = {
      country: values.country ?? null,
      language: state.language ?? null,
      site: values.siteaddress ?? null,
      entryId: state.entryId ?? null,
      device: values.devicecategory ?? null,
      ip: values.ip ?? null
    }
  },
  setDeeplinks (state, deeplinks) {
    state.deeplinks = deeplinks
  },
  setDevice (state, values) {
    state.device = Object.assign({}, state.device, values)
  },
  setDeviceType (state, value) {
    state.deviceType = value
  },
  setSiteType (state, value) {
    state.siteType = value
  },
  setSite (state, value) {
    state.site = value
  },
  setSiteName (state, value) {
    state.siteName = value
  },
  setSiteConfig (state, value) {
    state.siteConfig = value
  },
  setSiteBrand (state, value) {
    state.siteBrand = value
  },
  setCountry (state, value) {
    state.country = value
  },
  setIp (state, value) {
    state.ip = value
  },
  setCity (state, value) {
    state.city = value
  },
  setLanguage (state, value) {
    state.language = value
  },
  setRtl (state, value) {
    state.rtl = value
  },
  setEntryId (state, value) {
    state.entryId = value
  },
  setOstype (state, value) {
    state.ostype = value
  },
  setDebug (state, value) {
    state.isDebugEnabled = (parseInt(value) == 1)
  },
  setBrowser (state, values) {
    state.browser = Object.assign({}, state.browser, values)
  },
  setNewsletterModal (state, value) {
    state.isNewsletterModalOpen = value
  },
  setCompareToAdsModal (state, value) {
    state.isCompareToAdsModalLoaded = value
  },
  setInlineAdDeeplink (state, value) {
    state.inlineAdDeeplink = value
  },
  setIsGeolocated (state, value) {
    state.isGeolocated = value
  },
  setIsIdentified (state, value) {
    state.isIdentified = value
  },
  appendSearchResults (state, value) {
    state.searchResults = state.searchResults.concat(value)
  },
  resetSearchResults (state, value) {
    state.searchResults = []
  },
  setSearchFilters (state, filters) {
    state.searchFilters = {
      ...state.searchFilters,
      ...filters
    }
  },
  clearSearchFilters (state) {
    state.searchFilters = {
      stops: null,
      outboundDuration: null,
      inboundDuration: null
    }
  },
  setPassangers (state, value) {
    state.passangers = { ...value }
  },
  setSearchAirlines (state, value) {
    const existingAirlines = state.searchAirlines.reduce((acc, airline) => {
      acc[airline.code] = airline;
      return acc;
    }, {});
  
    state.searchAirlines = value.map(airline => {
      if (existingAirlines[airline.code]) {
        // Update the count for existing airlines
        return {
          ...existingAirlines[airline.code],
          count: airline.count
        };
      } else {
        // Add new airlines with checked property set to true
        return {
          ...airline,
          checked: true
        };
      }
    });
  },
  // get the filters provided by the api, add checked property and store it to render purposes
  setSearchCarsCheckboxes (state, value) {
    state.searchCarsCheckboxes = value
    Object.keys(state.searchCarsCheckboxes).map(key =>
      Object.keys(state.searchCarsCheckboxes[key].options).map((optionKey) => {
        state.searchCarsCheckboxes[key].options[optionKey].checked = !state.clearAllOptionsEnabled
      })
    )
  },
  // check/uncheck option
  setSearchOptionsChecked(state, { category, code, checked }) {
    if (state.searchCarsCheckboxes[category].options[code]) {
      state.searchCarsCheckboxes[category].options[code].checked = checked
    }
    if (Object.values(state.searchCarsCheckboxes[category].options).every(item => item.checked)) {
      state.clearAllOptionsEnabled = false
    }
  },
  clearSearchCarsFilters (state) {
    state.searchCarsFilters = {
      rejectedOptions: null,
      totalPrice: null
    }
  },
  setSearchCarsFilters (state, value) {
    state.searchCarsFilters = value
  },
  setSearchCarLocationCodes(state, { pickupCode, dropoffCode }) {
    state.searchCarLocationCodes = { pickupCode, dropoffCode }
  },
  setInlineAds (state, value) {
    state.inlineAds = value
  },
  setPageTitle (state, value) {
    state.pageTitle = value
  },
  storeEvent (state, value) {
    state.analyticEvents.push(value)
  },
  setTranslationRedirects (state, value) {
    state.translationRedirects = value
  },
  setRelatedSearch (state, value) {
    state.relatedSearch = value
  },
  setCompareItems (state, value) {
    state.compareItems = value
  },
  setSortingType (state, value) {
    state.sortingType = value
  },
  setSortResults (state, value) {
    state.sortResults = value
  },
  setLocalCurrency (state, value) {
    state.localCurrency = value
  },
  setHasLocalCurrency (state, value) {
    state.hasLocalCurrency = value
  }
}
