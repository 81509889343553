import { render, staticRenderFns } from "./ModalCompare.vue?vue&type=template&id=61079e9a"
import script from "./ModalCompare.vue?vue&type=script&lang=js"
export * from "./ModalCompare.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CompareFlightLeg: require('/home/forge/farescraper.com/components/CompareFlightLeg.vue').default,ModalCompareClickToCall: require('/home/forge/farescraper.com/components/ModalCompareClickToCall.vue').default,IconsExpand: require('/home/forge/farescraper.com/components/IconsExpand.vue').default,IconsCollapse: require('/home/forge/farescraper.com/components/IconsCollapse.vue').default,AppModal: require('/home/forge/farescraper.com/components/AppModal.vue').default})
